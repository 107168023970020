import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import APIIcon from "../../../assets/images/modeler/api.png";
import ABIcon from "../../../assets/images/modeler/ab.png";
import NotificationIcon from "../../../assets/images/modeler/notification.png";
import DialogueIcon from "../../../assets/images/modeler/dialog.png";
import QuestionIcon from "../../../assets/images/modeler/question.png";
import StartIcon from "../../../assets/images/modeler/start.png";
import Touch_appIcon from "../../../assets/images/modeler/touch.png";
import dynamicjurney from "../../../assets/images/modeler/dynamicjurney.png";
import userReg from "../../../assets/images/modeler/userreg.png";
import endIcon from "../../../assets/images/modeler/end.png";
import waitIcon from "../../../assets/images/modeler/wait.png";
import submitIcon from "../../../assets/images/modeler/submit.png";
import { ComponentType } from "../constants/componentType";

export const ActionPack = (props) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className="actionPack">
      <Tooltip className="modelerToolTip"  title="Start Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.START_NODE)}
          draggable
        >
          <img src={StartIcon} alt="start node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Question Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.QUESTION)}
          draggable
        >
          <img src={QuestionIcon} alt="question node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="ApiNode" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.API)}
          draggable
        >
          <img src={APIIcon} alt="api node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Dialog Node" arrow placement="right">
        <div
          onDragStart={(event) =>
            onDragStart(event, ComponentType.START_DIALOG)
          }
          draggable
        >
          <img src={DialogueIcon} alt="dialogue node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Component Node" arrow placement="right">
        <div
          onDragStart={(event) =>
            onDragStart(event, ComponentType.START_COMPONENT)
          }
          draggable
        >
          <img src={Touch_appIcon} alt="component node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Notification Node" arrow placement="right">
        <div
          onDragStart={(event) =>
            onDragStart(event, ComponentType.NOTIFICATION)
          }
          draggable
        >
          <img src={NotificationIcon} alt="notification node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="AB Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.AB)}
          draggable
        >
          <img src={ABIcon} alt="AB node icon" />
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="User Register" arrow placement="right">
        <div
          onDragStart={(event) =>
            onDragStart(event, ComponentType.USER_REGISTER)
          }
          draggable
        >
          {/* <span className="handleModellerIcon userReg"> */}
            <img src={userReg} alt="user register node icon" />
          {/* </span> */}
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Dynamic Journey Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.DYNAMIC)}
          draggable
        >
          {/* <span className="handleModellerIcon dynode"> */}
            <img src={dynamicjurney} alt="dynamic journey node icon" />
          {/* </span> */}
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Wait Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.WAIT_NODE)}
          draggable
        >
          {/* <span className="handleModellerIcon" style={{padding:0}}> */}
            <img src={waitIcon} alt="end journey node icon" />
          {/* </span> */}
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="Submit Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.SUBMIT_NODE)}
          draggable
        >
          {/* <span className="handleModellerIcon" style={{padding:0}}> */}
            <img src={submitIcon} alt="end journey node icon" />
          {/* </span> */}
        </div>
      </Tooltip>
      <Tooltip className="modelerToolTip"  title="End Node" arrow placement="right">
        <div
          onDragStart={(event) => onDragStart(event, ComponentType.END_NODE)}
          draggable
        >
          {/* <span className="handleModellerIcon" style={{padding:0}}> */}
            <img src={endIcon} alt="end journey node icon" />
          {/* </span> */}
        </div>
      </Tooltip>      
    </div>
  );
};
