import React from "react";
import axios from "axios";
import { getFunctions, getAllJourneys } from "../api/api";
import { ComponentType } from "../constants/componentType";
import { Style } from "../constants/style";
import { getData } from "../../../dataProvider/apiHandler";

const serverUrl = 
  window._env_.REACT_APP_SXP_URL;

export const setElementState = (
  element,
  setState,
  refreshElements,
  saveElements,
  onElementsRemove
) => {
  let state = {
    rightToggle: true,
    rightFormStyle: `${Style.RIGHTFORMCOLLAPSE} ${Style.RIGHTFORMOPEN}`,
    refreshElements: refreshElements,
    saveElements: saveElements,
    element: element,
    removeElement: onElementsRemove,
  };

  if (
    element.data.component === ComponentType.START_DIALOG ||
    element.data.component === ComponentType.NOTIFICATION
  ) {
    getAllJourneys().then((response) => {
      let conversations = response.data.data.docs.map((conversation) => {
        return { value: conversation.uid, label: conversation.name };
      });
      state.conversations = conversations;
      setState((prevState) => {
        return { ...prevState, ...state };
      });
    });
  } else if (element.data.component === ComponentType.EDGE) {
    getFunctions().then((response) => {
      let functions = response.data.functionNames.map((functionName) => {
        return { value: functionName, label: functionName };
      });
      state.functions = functions;
      setState((prevState) => {
        return { ...prevState, ...state };
      });
    });
  } else {
    setState((prevState) => {
      return { ...prevState, ...state };
    });
  }
};

export const loadFlow = (journeyId, selectedVersion) => {
  const allOption = {
    url: serverUrl + `flow/` + localStorage.getItem('pId'),
    params:{
      projectId: localStorage.getItem("pId"),
      version: localStorage.getItem("version"),
      journeyId: journeyId,
    }
  }
  return getData(allOption)
  .then((response) => {
    let flow = response.data.data;
    let nodes = [];
    let edges = [];
    if (flow.nodes) {
      for (let node of flow.nodes) {
        let nodeEle = {};
        nodeEle.id = node.id;
        if (node.component === 'START_NODE') {
          nodeEle.type = 'startNode';
        } 
        else if (node.component === 'END_NODE'){
          nodeEle.type = 'endNode';
        }
        else if (node.component === 'WAIT_NODE'){
          nodeEle.type = 'waitNode';
        }
        else if (node.component === 'SUBMIT_NODE'){
          nodeEle.type = 'submitNode';
        }
        else {
          nodeEle.type = 'component';
        }

        nodeEle.position = {};
        nodeEle.position.x = node.positionX;
        nodeEle.position.y = node.positionY;
        delete node.positionX;
        delete node.positionY;
        delete node.id;
        nodeEle.data = node;
        
        switch (node.component) {
          case ComponentType.QUESTION:
            if (node.isUserRegistrationComponent) {
              nodeEle.data.style = Style.URNODESTYLE;
            } else {
              nodeEle.data.style = Style.QUESTIONNODESTYLE;
            }
            break;
          case ComponentType.API:
            nodeEle.data.style = Style.APINODESTYLE;
            break;
          case ComponentType.START_NODE:
            nodeEle.data.style = Style;
            break;
          case ComponentType.START_DIALOG:
            nodeEle.data.style = Style.DIALOGNODESTYLE;
            break;
          case ComponentType.START_COMPONENT:
            nodeEle.data.style = Style.STARTCOMPONENTNODESTYLE;
            break;
          case ComponentType.NOTIFICATION:
            nodeEle.data.style = Style.NOTIFICATIONNODESTYLE;
            break;
          case ComponentType.AB:
            nodeEle.data.style = Style.ABNODESTYLE;
            break;
          case ComponentType.DYNAMIC:
            nodeEle.data.style = Style.DYNAMICNODESTYLE;
            break;
          case ComponentType.END_NODE:
            nodeEle.data.style = Style.ENDNODESTYLE;
            break;
          case ComponentType.WAIT_NODE:
            nodeEle.data.style = Style.WAITNODESTYLE;
            break;
          case ComponentType.SUBMIT_NODE:
            nodeEle.data.style = Style.SUBMITNODESTYLE;
            break;            
          default:
            break;
        }
        nodes.push(nodeEle);
      }
    }
    if (flow.edges) {
      for (let edge of flow.edges) {
        delete edge.version;
        let edgeEle = { ...edge };
        edgeEle.id = edge.id;
        if (edge.type === 'edge') {
          edgeEle.label = `{${edge.function ? edge.function : ''}}`;
          let style = { ...Style.CONNECTIONLABELSTYLE };
          if (edge.isLoopBack) {
            style.type = Style.LOOPBACKCONNECTIONSTYLE;
          }
          Object.assign(edgeEle, style);
          edgeEle.data = {
            component: ComponentType.EDGE,
            function: edge.function,
            isLoopBack: edge.isLoopBack,
          };
        } else {
          edgeEle.label = `${edge.abWeight ? edge.abWeight : ''}%`;
          let style = { ...Style.ABCONNECTIONLABELSTYLE };
          if (edge.isLoopBack) {
            style.type = Style.LOOPBACKCONNECTIONSTYLE;
          }
          Object.assign(edgeEle, style);
          edgeEle.data = {
            component: ComponentType.ABEDGE,
            abWeight: edge.abWeight,
            isLoopBack: edge.isLoopBack,
          };
        }
        edges.push(edgeEle);
      }
    }
    return nodes.concat(edges);
  });
};

export const getConversationsDropDown = (body) => {
let conversations = body.data.data;
let convosList =
  conversations.length > 0 &&
  conversations.map((item, i) => {
    return (
      <option key={i} value={item.id}>
        {item.name}
      </option>
    );
  }, this);
return convosList;
};
