import React from "react";
import Select from "react-select";
import { getFunctions } from "../api/api";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";
import FunctionPreview from "./FunctionPreview";

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

export class Connection extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    if (propsState.functions) {
      let func = propsState.functions.find(
        (x) => x.value === propsState.function
      );
      if (func) propsState.functionObj = func;
    }
    return propsState;
  }

  componentDidMount() {
    let onLoadState = { ...this.state };
    getFunctions().then((response) => {
      let functions = response.data.functionNames.map((functionName) => {
        return { value: functionName, label: functionName };
      });
      onLoadState.functions = functions;
      this.setState(onLoadState);
    });
  }

  handleSelectChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.function = e.value;
    this.setState(updatedState);
  };

  handleOnCreate = async (functionName) => {
    if (functionName) {
      const fnData = await getFunctions();
      const allFunctions = fnData.data.functionNames.map((fn) => {
        return { value: fn, label: fn };
      });
      this.setState({
        functions: allFunctions
      });
      const fnIsPresent = allFunctions.some(fn => fn.value === functionName);
      if (fnIsPresent) {
        this.setState({
          function: functionName,
          functionObj: { value: functionName, label: functionName },
        });
      }
    }
  };

  render() {
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props)}>
        <label> Function:</label>
        <FunctionPreview
          functionKey={this.state.function}
          onCreate={this.handleOnCreate}
        />
        <Select
          styles={style}
          name="function"
          className="searchSelectDDCS customSelect"
          options={this.state.functions}
          defaultValue={
            this.state.functionObj
              ? this.state.functionObj
              : { value: "", label: "" }
          }
          value={this.state.functionObj}
          key={this.state.formUniqueId}
          isSearchable
          onChange={this.handleSelectChange}
        />
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
