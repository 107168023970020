import React, { Component } from "react";
import SelectSearch from "react-select";
import Select from "@material-ui/core/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { getFunctions } from "../api/api";
import "react-tabs/style/react-tabs.css";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";
import closeRed from "../../../assets/images/subtract.png";
import FunctionPreview from "./FunctionPreview";

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

class Question extends Component {
  state = {
    recommendList: [],
    buttonFields: [],
    isButtonsDynamic: Boolean
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    if (propsState.functions) {
      let func = propsState.functions.find(
        (x) => x.value === propsState.funcName
      );
      if (func) propsState.functionObj = func;
    }
    return propsState;
  }

  componentDidMount() {
    getFunctions().then((response) => {
      // let functions = response.data.functionNames;
      let functions = response.data.functionNames.map((functionName) => {
        return { value: functionName, label: functionName };
      });
      this.setState({ functions });
      // this.setState({ functions });
    });
    this.fetchRecommendData(this.props.singleConvData);
  }

  componentWillUnmount() {
    this.setState({});
  }

  fetchRecommendData = (recoListDate) => {
    let recomedData =
      recoListDate &&
      recoListDate.selectedRecommendationTypes?.map((d) => d.type);
    this.setState({ recommendList: recomedData });
  };

  handleEvalAnswerChange = (e) => {
    let selected = e.target.value;
    if (selected === "Button" || selected === "Radio" ) {
      this.setState({ evalAnswer: selected, showButtonField: true });
    } else {
      this.setState({ evalAnswer: selected, showButtonField: false });
    }
  };

  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

  handleUtteranceChange = (e) => {
    // updatedState[e.target.name] = ;
    this.setState({
      utterance: { ...this.state.utterance?.en, en: e.target.value },
    });
  };

  recommendHandle = (e) => {
    let statusVal = e.target.checked;
    this.setState({ recommend: statusVal });
  };

  handleDynamicBtn = (e) => {
    let dynamicVal = e.target.checked;
    if(e.target.checked)
    {
      this.setState({buttonFields:[{}]})
    }
    else{
      this.setState({buttonsDynamicValue: ''})
    }
    this.setState({ isButtonsDynamic: dynamicVal });
  };

  handleOnChange = (e) => {
    let statusVal = e.target.checked;
    this.setState({ onChange: statusVal });
  };

  handleIsSearchable = (e) => {
    let statusVal = e.target.checked;
    this.setState({ isSearchable: statusVal });
  };

  handleIsmultiSelect = (e) => {
    let statusVal = e.target.checked;
    this.setState({ multiSelect: statusVal });
  }

  handleSelectChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.funcName = e.value;
    this.setState(updatedState);
  };

  handleFunctionName = (e) => {
    let updatedState = { ...this.state };
    updatedState.funcName = e.currentTarget.textContent;
    // updatedState.functionObj = {label: e.currentTarget.textContent, value: e.currentTarget.textContent }
    this.setState(updatedState);
  };

  handleRecommed = (e) => {
    let selectedVal = e.target.value;
    this.setState({ recommendationType: selectedVal });
  };

  handleAddNonDynamicBtn = () => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn.push({ label:"", value:"" });
    this.setState({buttonFields: nonDynBtn})
  }

  deleteNonDynBtn = (i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn.splice(i, 1);
    this.setState({buttonFields: nonDynBtn})
  }

  handlelabel = (e, i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn[i].label = e.target.value;
    this.setState({buttonFields: nonDynBtn})
  }

  handleKeyValue = (e, i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn[i].value = e.target.value;
    this.setState({buttonFields: nonDynBtn})
  }

  handleDynamicField = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  }

  handleOnCreate = async (functionName) => {
    if (functionName) {
      const fnData = await getFunctions();
      const allFunctions = fnData.data.functionNames.map((fn) => {
        return { value: fn, label: fn };
      });
      this.setState({
        functions: allFunctions
      });
      const fnIsPresent = allFunctions.some(fn => fn.value === functionName);
      if (fnIsPresent) {
        this.setState({
          funcName: functionName,
          functionObj: { value: functionName, label: functionName },
        });
      }
    }
  };

  render() {
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props, this.state)}>
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.state.name}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <div>
          <label> Reference </label>
          <input
            type="text"
            name="reference"
            defaultValue={this.state.reference}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <div>
          <label>Question </label>
          <input
            type="text"
            name="utterance"
            defaultValue={this.state.utterance?.en}
            value={this.state.utterance?.en}
            onChange={this.handleUtteranceChange}
            key={this.state.formUniqueId}
            required
          />
        </div>

        <Tabs>
          <TabList>
            <Tab>Input</Tab>
            <Tab>Preview</Tab>
          </TabList>
          <TabPanel>
            <label>
              Formatted Text
              <textarea
                name="richText"
                defaultValue={this.state.richText?.en}
                onChange={(e) =>
                  this.setState({
                    richText: {
                      ...this.state.richText?.en,
                      en: e.target.value,
                    },
                  })
                }
                key={this.state.formUniqueId}
              />
            </label>
          </TabPanel>
          <TabPanel>
            {this.state.richText && (
              <div
                style={{ border: "2px solid black", padding: 5 }}
                dangerouslySetInnerHTML={{ __html: this.state.richText.en }}
              />
            )}
          </TabPanel>
        </Tabs>

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={
              this.state.conversationRefresh === true ? true : false
            }
            name="conversationRefresh"
          />
          <label>Conversation Refresh </label>
        </div>

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.recommend === true ? true : false}
            name="recommend"
            onChange={this.recommendHandle}
          />
          <label> Recommend </label>
        </div>

        {this.state.recommend === true ? (
          <div className="pos-rel">
            <Select
              name="recommendationType"
              className="sxp-defaultDropDown_filed w100"
              defaultValue={this.state.recommendationType}
              value={this.state.recommendationType}
              onChange={this.handleRecommed}
            >
              {this.state.recommendList?.length ? (
                this.state.recommendList.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">no</MenuItem>
              )}
            </Select>
            {this.state.recommendationType ? null : (
              <FormHelperText className="projSelectPlaceholder">
                Select Recomendation
              </FormHelperText>
            )}
          </div>
        ) : null}

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.alwaysAsk === true ? true : false}
            name="alwaysAsk"
          />
          <label> Always Ask? </label>
        </div>
        <div>
          <label> Response Type: </label>
          <div className="pos-rel">
            <Select
              name="evalAnswer"
              className="sxp-defaultDropDown_filed w100"
              defaultValue={this.state.evalAnswer}
              onChange={this.handleEvalAnswerChange}
              key={this.state.formUniqueId}
            >
              <MenuItem value="String">String</MenuItem>
              <MenuItem value="Button">Button</MenuItem>
              <MenuItem value="Radio">Radio</MenuItem>
              <MenuItem value="Confirm">Confirm</MenuItem>
              <MenuItem value="Image">Image</MenuItem>
              <MenuItem value="Location">Location</MenuItem>
            </Select>
            {this.state.evalAnswer ? null : (
              <FormHelperText className="projSelectPlaceholder">
                Select Response Type
              </FormHelperText>
            )}
          </div>
        </div>

        {(this.state.evalAnswer === 'String' || this.state.evalAnswer === 'Confirm') && (
          <div>
            <input
              type="checkbox"
              name="onChange"
              key={this.state.formUniqueId}
              onClick={this.handleOnChange}
              defaultChecked={
                this.state.onChange === true ? true : false
              }
            />
            <label> On Change </label>
          </div>
          )}
          {(this.state.evalAnswer === 'String') && (
          <div>
            <input
              type="checkbox"
              name="isSearchable"
              key={this.state.formUniqueId}
              onClick={this.handleIsSearchable}
              defaultChecked={
                this.state.isSearchable === true ? true : false
              }
            />
            <label> Is Searchable </label>
          </div>
        )}
        
        <div>
          <label> Function: </label>
          <FunctionPreview
            functionKey={this.state.funcName}
            onCreate={this.handleOnCreate}
          />
          <SelectSearch
            styles={style}
            name="funcName"
            className="searchSelectDDCS customSelect"
            options={this.state.functions}
            defaultValue={
              this.state.functionObj
                ? this.state.functionObj
                : { value: "", label: "" }
            }
            value={this.state.functionObj}
            key={this.state.formUniqueId}
            onChange={this.handleSelectChange}
            isSearchable
          />

          {/* <Autocomplete
                id="custom-input-demo"
                name="funcName"
                options={this.state.functionsData?.map(a => a)}
                // onChange={(event) => this.autoComplete(event)}
                onChange={this.handleFunctionName}
                // defaultValue = {this.state.functionObj ? this.state.functionObj: null}
                value={this.state.functionObj ? this.state.functionObj: null}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                    <input type="text" placeholder="Search Function Name" {...params.inputProps} />
                    </div>
                )}
              /> */}
        </div>
        <div>
        
        {(this.state.showButtonField || this.state.evalAnswer === "Button") && (
          <>
          <div>
            <input
              type="checkbox"
              name="isButtonsDynamic"
              key={this.state.formUniqueId}
              onClick={this.handleDynamicBtn}
              // checked = {this.state.isButtonsDynamic}
              defaultChecked={this.state.isButtonsDynamic === true ? true : false}
            />
            <label> Dynamic Buttons </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="onChange"
              key={this.state.formUniqueId}
              onClick={this.handleOnChange}
              defaultChecked={
                this.state.onChange === true ? true : false
              }
            />
            <label> On Change </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="isSearchable"
              key={this.state.formUniqueId}
              onClick={this.handleIsSearchable}
              defaultChecked={
                this.state.isSearchable === true ? true : false
              }
            />
            <label> Is Searchable </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="multiSelect"
              key={this.state.formUniqueId}
              onClick={this.handleIsmultiSelect}
              defaultChecked={
                this.state.multiSelect === true ? true : false
              }
            />
            <label> MultiSelect </label>
          </div>

            {this.state.isButtonsDynamic ? (
              <>
              <div>
                <label> Button Fields </label>
                <input
                  type="text"
                  name="buttonsDynamicValue"
                  defaultValue={this.state.buttonsDynamicValue}
                  key={this.state.formUniqueId}
                  onChange={this.handleDynamicField}
                  required
                />
              </div>
                <input
                  type="hidden"
                  name="buttonsDynamicValue"
                  defaultValue={this.state.buttonsDynamicValue}
                  key={this.state.formUniqueId}
                />
                </>
            ) : (
              <div className="nonDynBtnObj" name="compeleteBtns">
                <div className="text-right"><span onClick={this.handleAddNonDynamicBtn} className="addNonDyBtn">+ Add </span></div>
                {this.state.buttonFields.map((d,i) => (
                  <div className="flex">
                    <input type="text" placeholder="label" onChange={(e) => this.handlelabel(e, i)} name="label" value={d.label}/>
                    <input type="text" placeholder="value" onChange={(e) => this.handleKeyValue(e, i)} name="value" value={d.value}/>
                    <span onClick={()=>this.deleteNonDynBtn(i)}>
                      <img
                        src={closeRed}
                        alt="close Icon"
                        className="closeIconM"
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        </div>
        <div>
          <label> Transaction Name </label>
          <input
            type="text"
            name="transactionName"
            onChange={this.handleTextInputChange}
            defaultValue={this.state.transactionName}
            key={this.state.formUniqueId}
          />
        </div>
        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.isBillable === true ? true : false}
            name="isBillable"
          />
          <label>Billable</label>
        </div>
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}

export default Question;
