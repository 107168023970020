import React from "react";
// import Select from 'react-select';
import Select from "@material-ui/core/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import SelectMui from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import { UserType } from "../constants/common";
import Whatsapp from "../../../assets/images/watsapp.png";
import fbmsngr from "../../../assets/images/fbmsngr.png";
import slack from "../../../assets/images/slack.png";
import line from "../../../assets/images/line.png";
import telegram from "../../../assets/images/telegram.png";
import webchat from "../../../assets/images/webchat.png";
import { getData } from "../../../dataProvider/apiHandler";
import { submit } from "./formActions";
import { FormButtons } from "./formbuttons";
import FindError from "../../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

export class Notification extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    propsState.conversations = nextProps.conversations;
    let userType = propsState.userType;
    if (userType === UserType.REGISTERED) {
      propsState.showRegisteredUserElements = true;
    } else if (userType === UserType.DIRECT) {
      propsState.showDirectChannelElements = true;
    } else {
      propsState.showRegisteredUserElements = false;
      propsState.showDirectChannelElements = false;
    }
    if (propsState.conversations) {
      let conversation = propsState.conversations.find(
        (x) => x.value === propsState.dialogId
      );
      if (conversation) propsState.conversationObj = conversation;
    }   
    return propsState;
  }

  componentDidMount = () => {
    this.getChannels();
  };

  componentWillUnmount() {
    this.setState({});
  }

  getChannels = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + "channel-config/" + localStorage.getItem('pId'),
        params: { projectId: localStorage.getItem("pId") },
      };
      let initialLoad = await getData(allOption);
      this.setState({
        allchannelsList: initialLoad.data.data.docs.map((d) => d.name),
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  handleSelectChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.dialogId = e.value;
    this.setState(updatedState);
  };

  handleUserTypeChange = (e) => {
    let userType = e.target.value;
    if (userType === UserType.REGISTERED) {
      this.setState({
        showRegisteredUserElements: true,
        userType: UserType.REGISTERED,
      });
    } else if (userType === UserType.DIRECT) {
      this.setState({
        showDirectChannelElements: true,
        userType: UserType.DIRECT,
      });
    } else {
      this.setState({
        showRegisteredUserElements: false,
        showDirectChannelElements: false,
        userType: UserType.TRANSACTIONAL,
      });
    }
  };

  handleDialogCheckBox = () => {
    let flag = this.state.isDialog ? true : false;
    this.setState({ isDialog: !flag });
  };

  handleTransactionalConv = () => {
    let flag = this.state.transactionalConversation ? true : false;
    this.setState({transactionalConversation : !flag });
    if(flag)
    {
      this.setState({transactionId:''})
    }
  }

  handleChange = async (event) => {
    this.setState({ channels: event.target.value });
  };

  handleNotifMessageChange = (e) => {
    this.setState({
      notificationMessage: {
        ...this.state.notificationMessage?.en,
        en: e.target.value,
      },
    });
  };

  render() {
    return (
      <form
        className="form-style"
        onSubmit={(e) => submit(e, this.props, { ...this.state })}
      >
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.state.name}
            key={this.state.formUniqueId}
            required
            autoFocus
          />
        </div>

        <div>
          <label> User Type: </label>

          <Select
            name="userType"
            defaultValue={
              this.state.userType ? this.state.userType : "transactional"
            }
            key={this.state.formUniqueId}
            onChange={this.handleUserTypeChange}
            className="sxp-defaultDropDown_filed w100"
          >
            <MenuItem value={UserType.TRANSACTIONAL}>TRANSACTIONAL</MenuItem>
            <MenuItem value={UserType.REGISTERED}>REGISTERED</MenuItem>
            <MenuItem value={UserType.DIRECT}>DIRECT CHANNEL</MenuItem>
          </Select>
        </div>

        <div>
          <label> Message:</label>
          <textarea
            name="notificationMessage"
            defaultValue={this.state.notificationMessage?.en}
            value={this.state.notificationMessage?.en}
            onChange={this.handleNotifMessageChange}
            rows="6"
            cols="30"
            key={this.state.formUniqueId}
          />
        </div>

        <Tabs>
          <TabList>
            <Tab>Input</Tab>
            <Tab>Preview</Tab>
          </TabList>
          <TabPanel>
            <label>
              Formatted Message:
              <textarea
                name="richText"
                defaultValue={this.state.richText?.en}
                rows="6"
                cols="30"
                onChange={(e) =>
                  this.setState({
                    richText: {
                      ...this.state.richText?.en,
                      en: e.target.value,
                    },
                  })
                }
                key={this.state.formUniqueId}
              />
            </label>
          </TabPanel>
          <TabPanel>
            {this.state.richText && (
              <div
                style={{ border: "2px solid black", padding: 5 }}
                dangerouslySetInnerHTML={{ __html: this.state.richText?.en }}
              />
            )}
          </TabPanel>
        </Tabs>

        {(this.state.showRegisteredUserElements ||
          this.state.showDirectChannelElements) && (
          <>
            <div>
              <label>
                {this.state.showDirectChannelElements
                  ? "User Channel ID"
                  : "User ID"}
              </label>
              <input
                type="text"
                name="authenticatedUserId"
                defaultValue={this.state.authenticatedUserId}
                key={this.state.formUniqueId}
                required
                autoFocus
              />
            </div>
            <label> Channel: </label>
            <FormControl className="notifi_channel">
              <SelectMui
                className="channel-select-dd"
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                disableUnderline
                value={this.state.channels || []}
                renderValue={(selected) =>
                  " Selected " + selected.length + "Channels"
                }
                onChange={this.handleChange}
                onClose={this.handleChangeBlur}
                inputProps={{ "aria-label": "Without label" }}
              >
                {this.state.allchannelsList
                  ? this.state.allchannelsList.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={this.state.channels?.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))
                  : []}
              </SelectMui>
              {this.state.channels?.length ? (
                ""
              ) : (
                <FormHelperText className="selectChannelButton">
                  Select Channel
                </FormHelperText>
              )}
            </FormControl>
            <div className="flex-box-header">
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("whatsapp") ? (
                      <img src={Whatsapp} className="channel-box" />
                    ) : null
                  )
                : []}
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("facebook") ? (
                      <img src={fbmsngr} alt="facebookIcon" className="channel-box" />
                    ) : null
                  )
                : []}
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("slack") ? (
                      <img src={slack} alt="slackIcon" className="channel-box" />
                    ) : null
                  )
                : []}
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("line") ? (
                      <img src={line} alt="lineIcon" className="channel-box" />
                    ) : null
                  )
                : []}
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("telegram") ? (
                      <img src={telegram} alt="telegramIcon" className="channel-box" />
                    ) : null
                  )
                : []}
              {this.state.channels
                ? this.state.channels.map((item) =>
                    item.includes("webchat") ? (
                      <img src={webchat} alt="webChatIcon" className="channel-box" />
                    ) : null
                  )
                : []}
            </div>
           
            <input
              type="checkbox"
              key={this.state.formUniqueId}
              defaultChecked={this.state.isDialog ? true : false}
              name="isDialog"
              onChange={this.handleDialogCheckBox}
            /><label> Start Conversation? </label>

            {(this.state.userType === 'REGISTERED' || this.state.userType === 'DIRECT') && this.state.isDialog && ( 
              <>
            <div>
              <label> Transferable State </label>
              <input
                type="text"
                name="transferableStateVars"
                defaultValue={this.state.transferableStateVars}
                key={this.state.formUniqueId}
              />
            </div>
              <div>
                <input
                type="checkbox"
                key={this.state.formUniqueId}
                defaultChecked={this.state.transactionalConversation ? true: false}
                name="transactionalConversation"
                onChange={this.handleTransactionalConv}
                />
                <label>Transactional Conversation?</label>
              </div>
              {this.state.transactionalConversation && (
                <>
                <label>Transaction Id</label>
                <input 
                type="text"
                name="transactionId"
                defaultValue={this.state.transactionId}
                key={this.state.formUniqueId}
                />
                </>
              )}
            </>
            )}
            
            {this.state.isDialog && (
              <>
                <label> Conversation:</label>
                <Select
                  name="dialogId"
                  defaultValue={this.state.conversationObj?.value}
                  key={this.state.formUniqueId}
                  onChange={this.handleSelectChange}
                  className="sxp-defaultDropDown_filed w100"
                >
                  {this.state.conversations.map((d,i)=> (
                    <MenuItem value={d.value}>{d.label}</MenuItem>
                  ))}
                  
                </Select>
              </>
            )}
          </>
        )}

        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
